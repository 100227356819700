<template>
  <v-container
    id="ficheromatriculados-caplp"
  >
    <v-row
      class="text-center"
    >
      <v-col
        xs12
        sm4
        md4
      >
        <v-text-field
          v-model="fapenom"
          label="Apellido y Nombre"
          outlined
          dense
          hide-details
          single-line
        />
      </v-col>
      <v-col
        xs12
        sm4
        md4
      >
        <v-select
          v-model="fidtipotitulo"
          :items="listatipostitulo"
          label="Tipo Titulo"
          outlined
          item-text="tipotitulo"
          item-value="idtipotitulo"
          dense
        />
      </v-col>
      <v-col
        xs12
        sm4
        md4
      >
        <v-btn
          class="ma-2"
          fab
          dark
          small
          color="primary"
          @click="ActualizaGrilla"
        >
          <v-icon
            dark
          >
            mdi-magnify
          </v-icon>
        </v-btn>
        <v-btn
          class="ma-2"
          fab
          dark
          small
          color="success"
          @click="NuevoMatriculado"
        >
          <v-icon
            dark
          >
            mdi-account-plus
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      class="text-center"
    >
      <v-col
        xs12
      >
        <v-data-table
          :headers="columnas"
          :items="listamatriculados"
          :items-per-page="10"
          :footer-props="{'items-per-page-text': 'Filas por Página:',
                          'items-per-page-all-text' : 'Todos'}"
          class="elevation-1"
          dense
          no-data-text="Sin Resultados"
          sort-by="['apenom']"
          multi-sort
        >
          <template
            slot="items"
            slot-scope="{ item }"
          >
            <td class="justify-center layout px-0">
              <v-icon
                small
                class="mr-2"
                @click="editMatriculado(props.item)"
              >
                edit
              </v-icon>
              <v-icon
                small
                @click="delMatriculado(props.item)"
              >
                delete
              </v-icon>
            </td>
            <td>{{ item.idpersona }}</td>
            <td>{{ item.nromatriculadosisant }}</td>
            <td>{{ item.apellido }}</td>
            <td>{{ item.nombre }}</td>
            <td>{{ item.documento }}</td>
            <td>{{ item.fechanac }}</td>
            <td>{{ item.sexo }}</td>
            <td>{{ item.delegacionorigen }}</td>
            <td>{{ item.matriculas }}</td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogmensaje"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          {{ mensajetitulo }}
        </v-card-title>
        <v-card-text>
          {{ mensajecuerpo }}
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="green darken-1"
            text
            @click="dialogmensaje = false"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model = "loading"
      full-width
    >
      <v-container
        fluid
        class = "full-height"
        style = "background-color: rgba(255, 255, 255, 0.5);"
      >
        <v-row
          align="center"
          justify="center"
          style="height:100vh"
          no-gutters
          dense
        >
          <v-col
            class = "elementocentrado"
          >
            <v-progress-circular
              indeterminate
              color = "primary"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
    <v-dialog
      v-model="dialogabm"
      persistent
      fullscreen
    >
      <v-card>
        <v-card-title class="headline">
          {{ tituloabm }}
        </v-card-title>
        <v-card-actions class="alineaderecha">
          <v-row
            row
            wrap
          >
            <v-col cols="12">
              <v-btn
                color="green darken-1"
                text
                @click="GrabarMatriculado"
              >
                Grabar
              </v-btn>
              <v-btn
                color="red darken-1"
                text
                @click="dialogabm = false"
              >
                Cancelar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-text>
          <v-row
            row
            wrap
          >
            <v-col
              cols="12"
            >
              <v-select
                v-model="mat.idestado"
                :items="listaestados"
                label="Estado Persona"
                outlined
                item-text="nombre"
                item-value="idestado"
                dense
                class="ocupa30"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-tabs
                centered
                color="#4bc47f"
                dark
                icons-and-text
              >
                <v-tabs-slider color="#2a6bbf" />
                <v-tab href="#tab-1">
                  Datos Personales
                  <v-icon>account_box</v-icon>
                </v-tab>
                <v-tab-item
                  value="tab-1"
                >
                  <v-card flat>
                    <v-card-text>
                      <v-row
                        row
                        wrap
                      >
                        <v-col
                          cols="12"
                          sm="4"
                          md="4"
                        >
                          <v-text-field
                            v-model="mat.apellido"
                            label="Apellido" />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="4"
                          md="4"
                          offset-xs0
                          offset-md1
                        >
                          <v-text-field
                            v-model="mat.nombre"
                            label="Nombre" />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="4"
                          md="4"
                          offset-xs0
                          offset-md1
                        >
                          <v-text-field
                            v-model="mat.nromatriculadosisant"
                            label="N° Sis. Anterior" />
                        </v-col>
                      </v-row>
                      <v-row
                        row
                        wrap
                      >
                        <v-col
                          cols="12"
                          sm="4"
                          md="4"
                        >
                          <v-select
                            v-model="mat.idtipodoc"
                            :items="listatiposdocumentos"
                            label="Tipo Documento"
                            outlined
                            item-text="nombre"
                            item-value="idtipodoc"
                            dense
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="4"
                          md="4"
                          offset-xs0
                          offset-md1
                        >
                          <v-text-field
                            v-model="mat.nrodoc"
                            label="N° Documento" />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="4"
                          md="4"
                          offset-xs0
                          offset-md1
                        >
                          <v-menu
                            ref="menufnac"
                            v-model="menufnac"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="mat.fecnac"
                            lazy
                            transition="scale-transition"
                            offset-y
                            full-width
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="mat.fecnac"
                                label="Fecha Nacim."
                                readonly
                                v-on="on"
                              />
                            </template>
                            <v-date-picker
                              v-model="mat.fecnac"
                              no-title
                              scrollable
                            >
                              <v-spacer />
                              <v-btn
                                flat
                                color="primary"
                                @click="menufnac = false"
                              >
                                Cancelar
                              </v-btn>
                              <v-btn
                                flat
                                color="primary"
                                @click="$refs.menufnac.save(mat.fecnac)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                      <v-row
                        row
                        wrap
                      >
                        <v-col
                          cols="12"
                          sm="4"
                          md="4"
                        >
                          <v-select
                            v-model="mat.idsexo"
                            :items="listasexos"
                            label="Sexo"
                            outlined
                            item-text="nombre"
                            item-value="idsexo"
                            dense
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="4"
                          md="4"
                          offset-xs0
                          offset-md1
                        >
                          <v-select
                            v-model="mat.iddelegacionorigen"
                            :items="listadelegaciones"
                            label="Delegación"
                            outlined
                            item-text="nombre"
                            item-value="iddelegacion"
                            dense
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="4"
                          md="4"
                          offset-xs0
                          offset-md1
                        >
                          <v-text-field
                            v-model="mat.cuitcuil"
                            label="CUIT/CUIL" />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab href="#tab-2">
                  Domicilios
                  <v-icon>apartment</v-icon>
                </v-tab>
                <v-tab-item
                  value="tab-2"
                >
                  <v-card flat>
                    <v-card-text>
                      <v-row
                        row
                        wrap
                      >
                        <v-col
                          cols="12"
                        >
                          <v-btn
                            class="ma-2"
                            fab
                            dark
                            small
                            color="success"
                            @click="newDomicilio"
                          >
                            <v-icon
                              dark
                            >
                              mdi-home-plus
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row
                        row
                        wrap
                      >
                        <v-col
                          cols="12"
                        >
                          <v-data-table
                            :headers="columnasdomicilios"
                            :items="mat.listadomicilios"
                            :items-per-page="10"
                            :footer-props="{'items-per-page-text': 'Filas por Página:',
                                            'items-per-page-all-text' : 'Todos'}"
                            class="elevation-1"
                            dense
                            no-data-text="Sin Domicilios"
                            sort-by="['iddomicilio']"
                            multi-sort
                          >
                            <template
                              slot="items"
                              slot-scope="{ item }"
                            >
                              <td class="justify-center layout px-0">
                                <v-icon
                                  small
                                  class="mr-2"
                                  @click="editDomicilio(item)"
                                >
                                  edit
                                </v-icon>
                                <v-icon
                                  small
                                  @click="delDomicilio(item)"
                                >
                                  delete
                                </v-icon>
                              </td>
                              <td class="alineacentro">{{ item.iddomicilio }}</td>
                              <td class="alineacentro">{{ item.domicilio }}</td>
                              <td class="alineacentro">{{ item.codpostal }}</td>
                              <td class="alineacentro">{{ item.localidad }}</td>
                              <td class="alineacentro">{{ item.tipodomicilio }}</td>
                              <td class="alineacentro">{{ item.activo === true ? 'SI' : 'NO' }}</td>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab href="#tab-3">
                  Telefonos
                  <v-icon>phone</v-icon>
                </v-tab>
                <v-tab-item
                  value="tab-3"
                >
                  <v-card flat>
                    <v-card-text>
                      <v-row
                        row
                        wrap
                      >
                        <v-col
                          cols="12"
                        >
                          <v-btn
                            class="ma-2"
                            fab
                            dark
                            small
                            color="success"
                            @click="newTelefono"
                          >
                            <v-icon
                              dark
                            >
                              mdi-phone-plus
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row
                        row
                        wrap
                      >
                        <v-col
                          cols="12"
                        >
                          <v-data-table
                            :headers="columnastelefonos"
                            :items="mat.listatelefonos"
                            :items-per-page="10"
                            :footer-props="{'items-per-page-text': 'Filas por Página:',
                                            'items-per-page-all-text' : 'Todos'}"
                            class="elevation-1"
                            dense
                            no-data-text="Sin Teléfonos"
                            sort-by="['idtelefono']"
                            multi-sort
                          >
                            <template
                              slot="items"
                              slot-scope="{ item }"
                            >
                              <td class="justify-center layout px-0">
                                <v-icon
                                  small
                                  class="mr-2"
                                  @click="editTelefono(props.item)"
                                >
                                  edit
                                </v-icon>
                                <v-icon
                                  small
                                  @click="delTelefono(props.item)"
                                >
                                  delete
                                </v-icon>
                              </td>
                              <td>{{ item.idtelefono }}</td>
                              <td>{{ item.tipotelefono }}</td>
                              <td>{{ item.codigoarea }}</td>
                              <td>{{ item.numero }}</td>
                              <td>{{ item.activo }}</td>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab href="#tab-4">
                  Correos
                  <v-icon>alternate_email</v-icon>
                </v-tab>
                <v-tab-item
                  value="tab-4"
                >
                  <v-card flat>
                    <v-card-text>
                      <v-row
                        row
                        wrap
                      >
                        <v-col
                          cols="12"
                        >
                          <v-btn
                            class="ma-2"
                            fab
                            dark
                            small
                            color="success"
                            @click="newCorreo"
                          >
                            <v-icon
                              dark
                            >
                              mdi-email-plus
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                        >
                          <v-data-table
                            :headers="columnascorreos"
                            :items="mat.listacorreos"
                            :items-per-page="10"
                            :footer-props="{'items-per-page-text': 'Filas por Página:',
                                            'items-per-page-all-text' : 'Todos'}"
                            class="elevation-1"
                            dense
                            no-data-text="Sin Correos"
                            sort-by="['idcorreo']"
                            multi-sort
                          >
                            <template
                              slot="items"
                              slot-scope="{ item }"
                            >
                              <td class="justify-center layout px-0">
                                <v-icon
                                  small
                                  class="mr-2"
                                  @click="editCorreo(props.item)"
                                >
                                  edit
                                </v-icon>
                                <v-icon
                                  small
                                  @click="delCorreo(props.item)"
                                >
                                  delete
                                </v-icon>
                              </td>
                              <td>{{ item.idcorreo }}</td>
                              <td>{{ item.correo }}</td>
                              <td>{{ item.activo }}</td>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab href="#tab-5">
                  Matriculas
                  <v-icon>recent_actors</v-icon>
                </v-tab>
                <v-tab-item
                  value="tab-5"
                >
                  <v-card flat>
                    <v-card-text>
                      <v-row
                        row
                        wrap
                      >
                        <v-col
                          cols="12"
                        >
                          <v-btn
                            class="ma-2"
                            fab
                            dark
                            small
                            color="success"
                            @click="newMatricula"
                          >
                            <v-icon
                              dark
                            >
                              mdi-account-box
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                        >
                          <v-data-table
                            :headers="columnasmatriculas"
                            :items="mat.listamatriculas"
                            :items-per-page="10"
                            :footer-props="{'items-per-page-text': 'Filas por Página:',
                                            'items-per-page-all-text' : 'Todos'}"
                            class="elevation-1"
                            dense
                            no-data-text="Sin Matrículas"
                            sort-by="['idmatricula']"
                            multi-sort
                          >
                            <template
                              slot="items"
                              slot-scope="{ item }"
                            >
                              <td class="justify-center layout px-0">
                                <v-icon
                                  small
                                  class="mr-2"
                                  @click="editMatricula(props.item)"
                                >
                                  edit
                                </v-icon>
                                <v-icon
                                  small
                                  @click="delMatricula(props.item)"
                                >
                                  delete
                                </v-icon>
                              </td>
                              <td>{{ item.idmatricula }}</td>
                              <td>{{ item.fechamatricula }}</td>
                              <td>{{ item.tipotitulo }}</td>
                              <td>{{ item.tomo }}</td>
                              <td>{{ item.folio }}</td>
                              <td>{{ item.delegacion }}</td>
                              <td>{{ item.estado }}</td>
                              <td>{{ item.fechabaja }}</td>
                              <td>{{ item.localidadradicacion }}</td>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogabmdomicilio"
      persistent
      max-width="800"
    >
      <v-card>
        <v-card-title class="headline">
          {{ tituloabmdomicilio }}
        </v-card-title>
        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              class="alineaderecha"
            >
              <v-btn
                color="green darken-1"
                text
                @click="GrabarDomicilio"
              >
                Grabar
              </v-btn>
              <v-btn
                color="red darken-1"
                text
                @click="dialogabmdomicilio = false"
              >
                Cancelar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-text>
          <v-row
            row
            wrap
          >
            <v-col
              cols="12"
              sm="10"
              md="10"
            >
              <v-text-field
                v-model="editeddomicilio.domicilio"
                label="Domicilio"
              />
            </v-col>
            <v-col
              cols="12"
              sm="2"
              md="2"
              offset-xs0
              offset-md1
            >
              <v-text-field
                v-model="editeddomicilio.codpostal"
                label="CP"
              />
            </v-col>
          </v-row>
          <v-row
            row
            wrap
          >
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <v-select
                v-model="editeddomicilio.idtipodomicilio"
                :items="listatiposdomicilio"
                label="Tipo"
                outlined
                item-text="nombre"
                item-value="idtipodomicilio"
                dense
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="6"
              offset-xs0
              offset-md1
            >
              <v-row
                row
                wrap
              >
                <v-col
                  cols="12"
                  sm="10"
                  md="10"
                >
                  <v-text-field
                    v-model="editeddomicilio.localidad"
                    label="Localidad"
                    readonly
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="2"
                  md="2"
                >
                  <v-btn
                    class="ma-2"
                    fab
                    dark
                    small
                    color="primary"
                    @click="AgreSelLocalidad"
                  >
                    <v-icon
                      dark
                    >
                      mdi-magnify
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row
            row
            wrap
          >
            <v-col
              cols="12"
            >
              <v-checkbox
                v-model="editeddomicilio.activo"
                label="Activo"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogabmtelefono"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title class="headline">
          {{ tituloabmtelefono }}
        </v-card-title>
        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              class="alineaderecha"
            >
              <v-btn
                color="green darken-1"
                text
                @click="GrabarTelefono"
              >
                Grabar
              </v-btn>
              <v-btn
                color="red darken-1"
                text
                @click="dialogabmtelefono = false"
              >
                Cancelar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-text>

        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogabmcorreo"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title class="headline">
          {{ tituloabmcorreo }}
        </v-card-title>
        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              class="alineaderecha"
            >
              <v-btn
                color="green darken-1"
                text
                @click="GrabarCorreo"
              >
                Grabar
              </v-btn>
              <v-btn
                color="red darken-1"
                text
                @click="dialogabmcorreo = false"
              >
                Cancelar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-text>

        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogabmmatricula"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title class="headline">
          {{ tituloabmmatricula }}
        </v-card-title>
        <v-card-actions>
          <v-btn
            color="green darken-1"
            text
            @click="GrabarMatricula"
          >
            Grabar
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="dialogabmmatricula = false"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
        <v-card-text>

        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogsellocalidad"
      persistent
      width="800"
    >
      <v-card>
        <v-card-title class="headline">
          Seleccione la Localidad
        </v-card-title>
        <v-card-actions>
          <v-row
            row
            wrap
          >
            <v-col
              cols="12"
              sm="5"
              md="5"
            >
              <v-select
                v-model="dialogsellocalidadidprovincia"
                :items="listaprovincias"
                label="Provincia"
                outlined
                item-text="provincia"
                item-value="idprovincia"
                dense
              />
            </v-col>
            <v-col
              cols="12"
              sm="5"
              md="5"
            >
              <v-text-field
                v-model="dialogsellocalidadnombre"
                label="Localidad"
              />
            </v-col>
            <v-col
              cols="12"
              sm="2"
              md="2"
              offset-xs0
              offset-md1
            >
              <v-btn
                color="green darken-1"
                text
                @click="ActualizaGrillaLocalidades(false)"
              >
                Listar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-text>
          <v-data-table
            :headers="columnaslocalidades"
            :items="listalocalidades"
            :items-per-page="10"
            :footer-props="{'items-per-page-text': 'Filas por Página:',
                            'items-per-page-all-text' : 'Todos'}"
            class="elevation-1"
            dense
            no-data-text="Sin Localidades"
            sort-by="['localidad']"
            multi-sort
          >
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td class="justify-center layout px-0">
                <v-icon
                  small
                  class="mr-2"
                  @click="seleccionarlocalidad(item)"
                >
                  done
                </v-icon>
              </td>
              <td>{{ item.idlocalidad }}</td>
              <td>{{ item.localidad }}</td>
              <td>{{ item.depto }}</td>
              <td>{{ item.provincia }}</td>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <ConfirmDlg ref="confirm" />
  </v-container>
</template>
<script>
  import axios from '@/axios'
  import ConfirmDlg from '../Confirm.vue'
  export default {
    name: 'FicheroMatriculados',
    components: {
      ConfirmDlg: ConfirmDlg
    },
    data () {
      return {
        loading: false,
        fapenom: '',
        fidtipotitulo: 0,
        listatipostitulo: [],
        listamatriculados: [],
        columnas: [
          {
            text: 'ID',
            align: 'center',
            sortable: true,
            value: 'idpersona',
            width: 10
          },
          {
            text: 'Apellido',
            align: 'center',
            sortable: true,
            value: 'apellido',
            width: 250
          },
          {
            text: 'Nombre',
            align: 'center',
            sortable: true,
            value: 'nombre',
            width: 250
          },
          {
            text: 'N° Mat Anterior',
            align: 'center',
            sortable: true,
            value: 'nromatriculadosisant',
            width: 150
          },
          {
            text: 'Documento',
            align: 'center',
            sortable: true,
            value: 'documento',
            width: 150
          },
          {
            text: 'Fecha Nacim',
            align: 'left',
            sortable: true,
            value: 'fechanac',
            width: 150
          },
          {
            text: 'Sexo',
            align: 'left',
            sortable: true,
            value: 'sexo',
            width: 100
          },
          {
            text: 'Delegación Origen',
            align: 'center',
            sortable: true,
            value: 'delegacionorigen',
            width: 200
          },
          {
            text: 'Matriculas',
            align: 'center',
            sortable: true,
            value: 'matriculas',
            width: 250
          }
        ],
        dialogmensaje: false,
        mensajetitulo: '',
        mensajecuerpo: '',
        dialogabm: false,
        tituloabm: '',
        dialogabmdomicilio: false,
        tituloabmdomicilio: '',
        dialogabmtelefono: false,
        tituloabmtelefono: '',
        dialogabmcorreo: false,
        tituloabmcorreo: '',
        dialogabmmatricula: false,
        tituloabmmatricula: '',
        mat: {
          idpersona: 0,
          apellido: '',
          nombre: '',
          nromatriculadosisant: '',
          idtipodoc: '',
          nrodoc: '',
          fecnac: '',
          idsexo: '',
          iddelegacionorigen: '',
          cuitcuil: '',
          idestado: '',
          listadomicilios: [],
          listatelefonos: [],
          listacorreos: [],
          listamatriculas: []
        },
        listatiposdocumentos: [],
        menufnac: false,
        listasexos: [],
        listadelegaciones: [],
        listaestados: [],
        columnasdomicilios: [
          {
            text: 'Op.',
            align: 'center',
            sortable: false,
            value: '',
            width: 10
          },
          {
            text: 'ID',
            align: 'center',
            sortable: true,
            value: 'iddomicilio',
            width: 10
          },
          {
            text: 'Domicilio',
            align: 'center',
            sortable: true,
            value: 'domicilio',
            width: 180
          },
          {
            text: 'CP',
            align: 'center',
            sortable: true,
            value: 'codpostal',
            width: 80
          },
          {
            text: 'Localidad',
            align: 'center',
            sortable: true,
            value: 'localidad',
            width: 120
          },
          {
            text: 'Tipo',
            align: 'center',
            sortable: true,
            value: 'tipodomicilio',
            width: 80
          },
          {
            text: 'Activo',
            align: 'center',
            sortable: true,
            value: 'activo',
            width: 80
          }
        ],
        columnastelefonos: [
          {
            text: 'ID',
            align: 'center',
            sortable: true,
            value: 'idtelefono',
            width: 10
          },
          {
            text: 'Tipo',
            align: 'center',
            sortable: true,
            value: 'tipotelefono',
            width: 100
          },
          {
            text: 'Código',
            align: 'center',
            sortable: true,
            value: 'codigoarea',
            width: 100
          },
          {
            text: 'Número',
            align: 'center',
            sortable: true,
            value: 'numero',
            width: 120
          },
          {
            text: 'Activo',
            align: 'center',
            sortable: true,
            value: 'activo',
            width: 80
          }
        ],
        columnascorreos: [
          {
            text: 'ID',
            align: 'center',
            sortable: true,
            value: 'idcorreo',
            width: 10
          },
          {
            text: 'Correo',
            align: 'center',
            sortable: true,
            value: 'correo',
            width: 250
          },
          {
            text: 'Activo',
            align: 'center',
            sortable: true,
            value: 'activo',
            width: 80
          }
        ],
        columnasmatriculas: [
          {
            text: 'ID',
            align: 'center',
            sortable: true,
            value: 'idmatricula',
            width: 10
          },
          {
            text: 'Fecha',
            align: 'center',
            sortable: true,
            value: 'fechamatricula',
            width: 80
          },
          {
            text: 'Tipo',
            align: 'center',
            sortable: true,
            value: 'tipotitulo',
            width: 120
          },
          {
            text: 'Tomo',
            align: 'center',
            sortable: true,
            value: 'tomo',
            width: 100
          },
          {
            text: 'Folio',
            align: 'center',
            sortable: true,
            value: 'folio',
            width: 100
          },
          {
            text: 'Delegación',
            align: 'center',
            sortable: true,
            value: 'delegacion',
            width: 150
          },
          {
            text: 'Estado',
            align: 'center',
            sortable: true,
            value: 'estado',
            width: 100
          },
          {
            text: 'Fecha Baja',
            align: 'center',
            sortable: true,
            value: 'fechabaja',
            width: 120
          },
          {
            text: 'Localidad Radicación',
            align: 'center',
            sortable: true,
            value: 'localidadradicacion',
            width: 150
          }
        ],
        editeddomicilio: {
          iddomicilio: 0,
          domicilio: '',
          codpostal: '',
          idlocalidad: 0,
          localidad: '',
          idtipodomicilio: 1,
          tipodomicilio: '',
          activo: true
        },
        dialogsellocalidad: false,
        listalocalidades: [],
        columnaslocalidades: [
          {
            text: '',
            align: 'center',
            sortable: false,
            value: '',
            width: 10
          },
          {
            text: 'ID',
            align: 'center',
            sortable: true,
            value: 'idlocalidad',
            width: 10
          },
          {
            text: 'ID',
            align: 'center',
            sortable: true,
            value: 'localidad',
            width: 150
          },
          {
            text: 'Depto',
            align: 'center',
            sortable: true,
            value: 'depto',
            width: 100
          },
          {
            text: 'Provincia',
            align: 'center',
            sortable: true,
            value: 'provincia',
            width: 100
          }
        ],
        dialogsellocalidadidprovincia: '',
        listatiposdomicilio: [],
        listaprovincias: []
      }
    },
    mounted () {
      this.CompletaCombos()
    },
    methods: {
      CompletaCombos () {
        this.loading = true
        axios
          .get('tipotitulo')
          .then((result) => {
            this.listatipostitulo = result.data
            axios
            .get('tipodomicilio')
            .then((result) => {
                this.listatiposdomicilio = result.data
                axios
                .get('provincia')
                .then((result) => {
                    this.listaprovincias = result.data
                    axios
                    .get('tipodocumento')
                    .then((result) => {
                        this.listatiposdocumentos = result.data
                        axios
                        .get('sexo')
                        .then((result) => {
                            this.listasexos = result.data
                            axios
                            .get('estadopersona')
                            .then((result) => {
                                this.listaestados = result.data
                                axios
                                .get('delegacion')
                                .then((result) => {
                                    this.listadelegaciones = result.data
                                    this.ActualizaGrilla()
                                })
                                .catch((error) => {
                                    this.loading = false
                                    this.MostrarMensaje('Error', error)
                                })
                            })
                            .catch((error) => {
                                this.loading = false
                                this.MostrarMensaje('Error', error)
                            })
                        })
                        .catch((error) => {
                            this.loading = false
                            this.MostrarMensaje('Error', error)
                        })
                    })
                    .catch((error) => {
                        this.loading = false
                        this.MostrarMensaje('Error', error)
                    })
                })
                .catch((error) => {
                    this.loading = false
                    this.MostrarMensaje('Error', error)
                })
            })
            .catch((error) => {
                this.loading = false
                this.MostrarMensaje('Error', error)
            })
          })
          .catch((error) => {
            this.loading = false
            this.MostrarMensaje('Error', error)
          })
      },
      ActualizaGrilla () {
        this.loading = true
        var apenom = this.fapenom === '' ? ' ' : this.fapenom
        axios
          .get('matriculado/' + apenom + '/' + this.fidtipotitulo)
          .then((result) => {
            this.listamatriculados = result.data
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            this.MostrarMensaje('Error', error)
          })
      },
      MostrarMensaje (titulo, cuerpo) {
        this.mensajetitulo = titulo
        this.mensajecuerpo = cuerpo
        this.dialogmensaje = true
      },
      NuevoMatriculado () {
        this.loading = true
        this.tituloabm = 'Nuevo Matriculado'
        this.mat = {
          idpersona: 0,
          apellido: '',
          nombre: '',
          nromatriculadosisant: '',
          idtipodoc: '',
          nrodoc: '',
          fecnac: '',
          idsexo: '',
          iddelegacionorigen: '',
          cuitcuil: '',
          idestado: 1,
          listadomicilios: [],
          listatelefonos: [],
          listacorreos: [],
          listamatriculas: []
        }
        this.dialogabm = true
        this.loading = false
      },
      GrabarMatriculado () {

      },
      editMatriculado (item) {
        this.loading = true
        this.tituloabm = 'Edita Persona ID ' + item.idpersona
        this.dialogabm = true
        this.loading = false
      },
    //   delMatriculado (item) {

    //   },
      newDomicilio () {
        this.loading = true
        this.tituloabmdomicilio = 'Nuevo Domicilio'
        this.editeddomicilio = {
          iddomicilio: 0,
          domicilio: '',
          codpostal: '',
          idlocalidad: 300,
          localidad: 'SANTA ROSA',
          idtipodomicilio: 1,
          tipodomicilio: this.listatiposdomicilio.filter(d => d.idtipodomicilio === 1)[0].nombre,
          activo: true
        }
        this.loading = false
        this.dialogabmdomicilio = true
      },
      editDomicilio (item) {
        this.loading = true
        this.tituloabmdomicilio = 'Edita Domicilio'
        this.editeddomicilio = {
          iddomicilio: item.iddomicilio,
          domicilio: item.domicilio,
          codpostal: item.codpostal,
          idlocalidad: item.idlocalidad,
          localidad: item.localidad,
          idtipodomicilio: item.idtipodomicilio,
          tipodomicilio: item.tipodomicilio,
          activo: item.activo
        }
        this.loading = false
        this.dialogabmdomicilio = true
      },
      async delDomicilio (item) {
        if (await this.$refs.confirm.open('Confirmar Borrado', 'Esta seguro de querer borrar el registro?')) {
          this.mat.listadomicilios.splice(this.mat.listadomicilios.indexOf(item), 1)
        }
      },
      GrabarDomicilio () {
        this.loading = true
        this.editeddomicilio.tipodomicilio = this.listatiposdomicilio.filter(d => d.idtipodomicilio === this.editeddomicilio.idtipodomicilio)[0].nombre
        if (this.editeddomicilio.activo === true) {
          var idtipodom = this.editeddomicilio.idtipodomicilio
          this.mat.listadomicilios.map(function (dato) {
            if (dato.idtipodomicilio === idtipodom && dato.activo === true) {
              dato.activo = false
            }
          })
          this.mat.listadomicilios.push(this.editeddomicilio)
          this.loading = false
          this.dialogabmdomicilio = false
        }
      },
      newTelefono () {
        this.tituloabmtelefono = 'Nuevo Teléfono'
        this.dialogabmtelefono = true
      },
      editTelefono (item) {
        this.tituloabmtelefono = 'Edita Telefono ID ' + item.idtelefono
        this.dialogabmtelefono = true
      },
    //   delTelefono (item) {

    //   },
      GrabarTelefono () {

      },
      newCorreo () {
        this.tituloabmcorreo = 'Nuevo Correo'
        this.dialogabmcorreo = true
      },
      editCorreo (item) {
        this.tituloabmcorreo = 'Edita Correo ID ' + item.idcorreo
        this.dialogabmcorreo = true
      },
    //   delCorreo (item) {

    //   },
      GrabarCorreo () {

      },
      newMatricula () {
        this.tituloabmmatricula = 'Nueva Matrícula'
        this.dialogabmmatricula = true
      },
    //   editMatricula (item) {
    //     this.dialogabmmatricula = true
    //   },
    //   delMatricula (item) {

    //   },
      GrabarMatricula () {

      },
      AgreSelLocalidad () {
        this.dialogsellocalidadidprovincia = 42
        this.dialogsellocalidadnombre = 'Santa Rosa'
        this.ActualizaGrillaLocalidades(true)
      },
      ActualizaGrillaLocalidades (abredialogalfinalizar) {
        axios
          .get('localidad/' + this.dialogsellocalidadidprovincia + '/' + this.dialogsellocalidadnombre)
          .then((result) => {
            this.listalocalidades = result.data
            if (abredialogalfinalizar) {
              this.dialogsellocalidad = true
            }
          })
          .catch((error) => {
            this.loading = false
            this.MostrarMensaje('Error', error)
          })
      },
      seleccionarlocalidad (item) {
        this.editeddomicilio.idlocalidad = item.idlocalidad
        this.editeddomicilio.localidad = item.localidad
        this.dialogsellocalidad = false
      }
    }
  }
</script>
<style>
  .textocentrado{
    text-align: center;
  }
  .elementocentrado{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
    padding: 2px !important;
  }
  @media only screen and (min-width: 960px) {
    .altojusto{
      height: 45px !important;
    }
  }
  .alineaizquierda{
    text-align: left;
  }
  .alineacentro{
    text-align: center;
  }
  .alineaderecha{
    text-align: right;
  }
  .ocupa30{
    width: 30%;
  }
</style>
